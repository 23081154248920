export const gateway = "https://oraclerms-bms-api.link.wawanesalife.com";
export const LoginUrlParameter = "https://stable-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=1psrg08sd6lo699oeah4tfe75l&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Foraclerms-bms.link.wawanesalife.com%2Fredirect";
export const BrokerPoolClientParameter = "1psrg08sd6lo699oeah4tfe75l";
export const BrokerPoolDomainParameter = "stable-brokers";
export const BrokerPoolParameter = "ca-central-1_Q5DSclAvd";

export const brokerBranding = {
	"oraclerms.link.wawanesalife.com": {
		a: {
			broker: "oraclerms",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.oraclerms.com/privacy/",
			scope: "oraclerms#branding#oraclerms.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "info@oraclerms.com",
			video_link: "null",
			map2: "hide",
			environment_id: "stable",
			map1: "100 Drumlin Circle, Suite 101,Concord,ON,L4K 3E5",
			brands: "oraclerms.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "null",
			phone: "1-905-660-9740",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.oraclerms.com/",
			tcLink: "null",
			ftcLink: "null",
			layout: "modern",
			g4: "null",
			photo3: "show",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Reduce Your Risk",
			approve_user: "link_oraclerms",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "oraclerms.link.wawanesalife.com",
		},
		b: {
			broker: "oraclerms",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.oraclerms.com/privacy/",
			scope: "oraclerms#branding#oraclerms.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "info@oraclerms.com",
			video_link: "null",
			map2: "hide",
			environment_id: "stable",
			map1: "100 Drumlin Circle, Suite 101,Concord,ON,L4K 3E5",
			brands: "oraclerms.link.wawanesalife.com",
			map4: "hide",
			map3: "hide",
			introduction: "null",
			phone: "1-905-660-9740",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.oraclerms.com/",
			tcLink: "null",
			ftcLink: "null",
			layout: "modern",
			g4: "null",
			photo3: "show",
			photo4: "show",
			photo1: "show",
			photo2: "show",
			tagline: "Reduce Your Risk",
			approve_user: "link_oraclerms",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "oraclerms.link.wawanesalife.com",
		},
	},
};

//updated on Thu May 30 2024 10:42:29 GMT-0700 (Pacific Daylight Time)
